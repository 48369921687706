<template>
  <div
    class="transferPage"
    v-loading="isLoading"
    element-loading-text="加载中..."
  ></div>
</template>

<script>
import { _localStorage } from "@/utils/utils";
import { getOauth } from "./api";
import { AESEcbEncrypt } from "@/utils/common";
export default {
  name: "transferPage",
  components: {},
  props: {},
  data() {
    return {
      menu: [],
      isLoading: true,
      urlData: null,
    };
  },
  created() {
    const query = this.getSearchData(window.location.search);
    if (Object.prototype.hasOwnProperty.call(query, "token")) {
      this.getInfo(query);
    } else {
      this.isLoading = false;
      this.$router.replace({
        name: "login",
      });
    }
    // 进入该页面则清除所有缓存
    sessionStorage.clear();
    localStorage.clear();
  },
  mounted() {},
  methods: {
    getInfo(params) {
      this.$axios
        .post(`${getOauth}`, null, {
          params: params,
        })
        .then(async (res) => {
          if (res.code == 200) {
            this.isLoading = false;
            let userInfo = res.data;
            _localStorage.setItem("userInfo", JSON.stringify(userInfo));
            this.$store.commit("app/setUserInfo", userInfo);
            console.log(this.$store.state.app.userInfo, 976);
            this.$router.replace({
              name: "guide",
            });
          } else {
            window.open(params.redirectUrl, "_blank");
          }
        })
        .catch(() => {
          window.open(params.redirectUrl, "_blank");
        });
    },
    async getMenu() {
      let params = {
        roleId: this.$store.state.app.userInfo.roleId,
        tenantId: this.$store.state.app.userInfo.tenantId,
        userId: this.$store.state.app.userInfo.userId,
      };
      let res = await this.$axios.post(
        `/gateway/blade-system/menu/routes`,
        params,
        {
          params: {
            domain: this.$store.state.app.userInfo.domain,
          },
        }
      );
      if (res.code === 200) {
        this.menu = res.data;
      }
    },
    findUrl(oList) {
      console.log(oList, 4567);
      if (oList[0] && oList[0].url) {
        this.urlData = oList[0];
        return "";
      } else if (oList[0] && oList[0].url.length == 0) {
        if (!oList[0].children) {
          this.urlData = oList[0];
          return "";
        } else {
          this.findUrl(oList[0].children);
        }
      } else {
        console.log(oList[0].children, 4567);
        this.findUrl(oList[0].children);
      }
    },
    getSearchData(search) {
      let obj = {};
      let arr = search.slice(1).split("&");
      arr.forEach((item) => {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
      });
      return obj;
    },
  },
};
</script>

<style scoped lang="less">
.transferPage {
  width: 100%;
  height: 100%;
}
</style>
